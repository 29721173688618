var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"width":1000,"maskClosable":false,"title":"选择考题","ok-text":"确认","cancel-text":"取消"},on:{"cancel":_vm.cancel,"ok":_vm.confirm},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules,"layout":"vertical","label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"考题","prop":"question_bank_id"}},[_c('a-transfer',{attrs:{"titles":['全部题目', '已选题目'],"data-source":_vm.dataList,"target-keys":_vm.formData.question_bank_id,"disabled":_vm.disabled,"show-search":_vm.showSearch,"filter-option":(inputValue, item) => item.title.indexOf(inputValue) !== -1,"show-select-all":false},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function({
            props: { filteredItems, selectedKeys, disabled: listDisabled },
            on: { itemSelectAll, itemSelect },
          }){return [_c('a-table',{style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"row-selection":_vm.getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect }),"columns":_vm.commonColumns,"data-source":filteredItems,"size":"small","custom-row":({ key, disabled: itemDisabled }) => ({
                on: {
                  click: () => {
                    if (itemDisabled || listDisabled) return;
                    itemSelect(key, !selectedKeys.includes(key));
                  },
                },
              })}})]}}])})],1),_c('a-form-model-item',{attrs:{"label":"考试总分","prop":"total_score"}},[_c('a-input-number',{attrs:{"placeholder":"考试总分","allowClear":"","disabled":""},model:{value:(_vm.formData.total_score),callback:function ($$v) {_vm.$set(_vm.formData, "total_score", $$v)},expression:"formData.total_score"}})],1),_c('a-form-model-item',{attrs:{"label":"成绩及格线","prop":"num"}},[_c('a-space',[_c('a-input-number',{attrs:{"placeholder":"考试总分","allowClear":"","disabled":""},model:{value:(_vm.formData.total_score),callback:function ($$v) {_vm.$set(_vm.formData, "total_score", $$v)},expression:"formData.total_score"}}),_c('span',[_vm._v("X")]),_c('a-input-number',{attrs:{"placeholder":"请输入1-100之间的数字（%）","min":1,"max":100,"step":1,"allowClear":""},model:{value:(_vm.formData.num),callback:function ($$v) {_vm.$set(_vm.formData, "num", $$v)},expression:"formData.num"}}),_c('span',[_vm._v("=")]),_c('a-input-number',{attrs:{"placeholder":"及格线分数","allowClear":"","disabled":""},model:{value:(_vm.passing_mark),callback:function ($$v) {_vm.passing_mark=$$v},expression:"passing_mark"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }